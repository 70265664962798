import { type FC, useEffect, useState } from 'react'
import { Button, Stack,  TextInput, Title, Loader  } from '@mantine/core'
import { useUserData } from '@nhost/react'
import { useCreateSwipeSessionMutation, useGetSwipeSessionQuery, useUpdateSwipeSessionMutation, useGetSwipeResultsQuery, useGetUserBySlugQuery, useGetBoardGamesWithFilterQuery } from '../../graphql/graphql'
import { SwipeResults } from '../../components/SwipeResults'
import { SwipeInterface } from '../../components/SwipeInterface'
import { ProgressView } from '../../components/ProgressView'
import { SwipeWaitingRoom } from '../../components/SwipeWaitingRoom'
import { filterGamesByPlayerCount } from '../../lib/gameFilters'

interface ISwipeHostScreenProps {
  userId: string
}

export const SwipeHostScreen:FC<ISwipeHostScreenProps> = ({ userId }) =>  {
  const [viewingLibraryOfUserId, setViewingLibraryOfUserId] = useState<string | null>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [myParticipantId, setMyParticipantId] = useState<string | null>(null)
  const user = useUserData()
  const [userIdentifier, setUserIdentifier] = useState<string | null>(user?.displayName ?? null)
  const [allFinished, setAllFinished] = useState(false)

  const { data: userData, isLoading } = useGetUserBySlugQuery({ slug: userId.toLowerCase() })

  const { data: sessionData } = useGetSwipeSessionQuery(
    { session_id: sessionId ?? '' },
    {
      enabled: !!sessionId,
      refetchInterval: allFinished ? false : 1000, // Stop polling when all finished
    }
  )

  const { data: resultsData } = useGetSwipeResultsQuery(
    { session_id: sessionId ?? '' },
    {
      enabled: !!sessionId && sessionData?.swipe_sessions_by_pk?.status === 'in_progress',
      refetchInterval: allFinished ? false : 1000, // Stop polling when all finished
    }
  )

  const { data: gamesData } = useGetBoardGamesWithFilterQuery(
    {
      uuid: sessionData?.swipe_sessions_by_pk?.library_uuid || '',
      players: sessionData?.swipe_sessions_by_pk?.player_count || 1,
      playTimeInMinutesMin: sessionData?.swipe_sessions_by_pk?.min_play_time || 0,
      playTimeInMinutesMax: sessionData?.swipe_sessions_by_pk?.max_play_time || 9999,
    },
    {
      enabled: !!sessionData?.swipe_sessions_by_pk?.library_uuid,
    }
  )

  useEffect(() => {
    // When user data is available (either logged in or confirmed not logged in)
    if (user !== undefined) {
      if (user) {
        setUserIdentifier(user.displayName)
      }
    }
  }, [user])

  useEffect(() => {
    if (viewingLibraryOfUserId) return
    if (!userData) return
    setViewingLibraryOfUserId(userData.users[0].id)
  }, [userData, viewingLibraryOfUserId])
  
  useEffect(() => {
    if (user && viewingLibraryOfUserId && !sessionId) {
      handleCreateSession()
    }
  }, [user, viewingLibraryOfUserId, sessionId])

  const createSessionMutation = useCreateSwipeSessionMutation({
    onSuccess: (data) => {
      if (data.insert_swipe_sessions?.returning[0]) {
        setSessionId(data.insert_swipe_sessions.returning[0].session_id)
        setMyParticipantId(data.insert_swipe_sessions.returning[0].session_participants[0].participant_id)
      }
    },
  })

  const updateSessionMutation = useUpdateSwipeSessionMutation()

  const handleCreateSession = () => {
    createSessionMutation.mutate({ library_uuid: viewingLibraryOfUserId, user_identifier: userIdentifier })
  }

  const handleStartSession = (settings: {
    playerCount: number
    minPlayTime: number | null
    maxPlayTime: number | null
  }) => {
    if (!sessionId) return
    updateSessionMutation.mutate({
      session_id: sessionId,
      status: 'in_progress',
      player_count: settings.playerCount,
      min_play_time: settings.minPlayTime,
      max_play_time: settings.maxPlayTime
    })
  }

  if (isLoading){
    return (
      <Stack align="center" gap="xl" p="xl">
        <Title order={1}>Loading...</Title>
        <Loader size="xl" />
      </Stack>
    )
  }

  // if (!isLoading && viewingLibraryOfUserId && userIdentifier && !sessionInitializing) {
  //   setSessionInitializing(true)
  //   handleCreateSession()
  // }

  if (!sessionId) {
    return (
      <Stack align="center" gap="xl" p="xl">
        <Title order={1}>Create Swipe Session</Title>
        <TextInput
          label="Your Nickname"
          placeholder="Enter a nickname"
          onChange={(event) => setUserIdentifier(event.target.value)}
        />
        <Button
          onClick={handleCreateSession}
          loading={createSessionMutation.isPending}
        >
          Create New Session
        </Button>
      </Stack>
    )
  }

  const sessionUrl = `${window.location.origin}/swipe_session/${sessionId}`

  // Show swipe interface or progress view when session is in progress
  if (sessionData?.swipe_sessions_by_pk?.status === 'in_progress') {
    const playerCount = sessionData.swipe_sessions_by_pk.player_count || 1
    const allGames = gamesData?.users[0]?.boardGames.map(bg => bg.boardGame) || []
    const filteredGames = filterGamesByPlayerCount(allGames, playerCount, true)

    if (allGames.length === 0) {
      return (
        <Stack align="center" gap="xl" p="xl">
          <Title order={1}>Loading Games...</Title>
          <Loader size="xl" />
        </Stack>
      )
    }

    // Check if host has completed their swipes
    const hostResults = resultsData?.swipe_results.filter(
      result => result.participant_id === ( myParticipantId ?? user?.id ?? '')
    ) || []

    console.log("hostResults", hostResults.length, filteredGames.length)
    if (hostResults.length === filteredGames.length) {
      // Show progress view if host is done swiping
      if (!resultsData) {
        return (
          <Stack align="center" gap="xl" p="xl">
            <Title order={1}>Loading Results...</Title>
            <Loader size="xl" />
          </Stack>
        )
      }

      // Check if all participants have finished
      const participants = sessionData.swipe_sessions_by_pk.session_participants
      const allParticipantsFinished = participants.every(participant => {
        const participantResults = resultsData.swipe_results.filter(
          result => result.participant_id === participant.participant_id
        )
        return participantResults.length === filteredGames.length
      })

      if (allParticipantsFinished) {
        // Set allFinished to true to stop polling
        if (!allFinished) {
          setAllFinished(true)
        }
        return <SwipeResults sessionId={sessionId} />
      }

      return (
        <ProgressView
          sessionData={sessionData.swipe_sessions_by_pk}
          resultsData={resultsData}
          gamesData={filteredGames}
          currentParticipantId={ myParticipantId ?? user?.id ?? ''}
        />
      )
    }

    // Show swipe interface if host hasn't finished swiping
    return (
      <Stack>
        <SwipeInterface
          sessionId={sessionId || ''}
          participantId={myParticipantId ?? user?.id ?? ''}
          games={filteredGames}
        />
      </Stack>
    )
  }

  return (
    <SwipeWaitingRoom
      sessionUrl={sessionUrl}
      participants={sessionData?.swipe_sessions_by_pk?.session_participants || []}
      onStartSession={handleStartSession}
      isStarting={updateSessionMutation.isPending}
    />
  )
}
