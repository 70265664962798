import { Stack, Title, Text, Card, Group, Badge } from '@mantine/core'
import { useGetSwipeResultsQuery } from '../../graphql/graphql'
import { cloudinaryBaseUrl } from '#lib/helpers.ts'

interface SwipeResultsProps {
    sessionId: string
}

export function SwipeResults({ sessionId }: SwipeResultsProps) {
    const { data: resultsData } = useGetSwipeResultsQuery(
        { session_id: sessionId },
        {
            refetchInterval: 1000, // Poll every second
        }
    )

    if (!resultsData?.swipe_results.length) {
        return (
            <Stack align="center" gap="xl" p="xl">
                <Title order={1}>No Results Yet</Title>
                <Text>Waiting for participants to finish rating games...</Text>
            </Stack>
        )
    }

    // Group results by game
    const gameResults = resultsData.swipe_results.reduce((acc, result) => {
        const gameId = result.game_uuid
        if (!acc[gameId]) {
            acc[gameId] = {
                game: result.board_game,
                likes: 0,
                total: 0,
                participants: [],
            }
        }
        acc[gameId].total++
        if (result.liked) {
            acc[gameId].likes++
        }
        acc[gameId].participants.push({
            name: result.session_participant?.user_identifier ?? 'Unknown',
            liked: result.liked,
        })
        return acc
    }, {} as Record<string, {
        game: typeof resultsData.swipe_results[number]['board_game']
        likes: number
        total: number
        participants: Array<{ name: string; liked: boolean }>
    }>)

    // Sort games by percentage of likes
    const sortedGames = Object.values(gameResults).sort(
        (a, b) => (b.likes / b.total) - (a.likes / a.total)
    )

    return (
        <Stack align="center" gap="xl" p="xl">
            <Title order={1}>Swipe Results</Title>

            <Stack gap="md" w="100%" maw={600}>
                {sortedGames.map((result) => (
                    <Card key={result.game.uuid} shadow="sm" padding="lg" radius="md" withBorder>
                        <Group wrap="nowrap" gap="md">
                            <img
                                src={result.game.cloudinaryPublicId ? `${cloudinaryBaseUrl(360, 360)}/${result.game.cloudinaryPublicId}` : result.game.thumbnailUrl}
                                alt={result.game.title}
                                style={{ width: 100, height: 100, objectFit: 'cover', borderRadius: 8 }}
                            />
                            <Stack gap="xs">
                                <Title order={2} size="h4">{result.game.title}</Title>
                                <Group gap="xs">
                                    <Badge color={result.likes === result.total ? 'green' : result.likes === 0 ? 'red' : 'yellow'}>
                                        {Math.round((result.likes / result.total) * 100)}% Match
                                    </Badge>
                                    { result.likes > 0 &&
                                    <Text size="sm" c="dimmed">
                                        {result.likes} out of {result.total} liked this game
                                    </Text>
                                    }
                                </Group>
                                { result.likes > 0 &&
                                <Text size="sm">
                                    Liked by: {result.participants.filter(p => p.liked).map(p => p.name).join(', ')}
                                    </Text>
                                }
                            </Stack>
                        </Group>
                    </Card>
                ))}
            </Stack>
        </Stack>
    )
}
