import { FC  } from 'react'
import { Card, Title, Text, Button, Stack } from '@mantine/core'
import { IconCheck, IconLibrary } from '@tabler/icons-react'
import { Link } from '@tanstack/react-router'
import styles from './styles.module.scss'
import Confetti from 'react-confetti-boom'

export const PaymentSuccessScreen: FC = () => {

  return (
    <>
        <Confetti mode="boom" particleCount={150} colors={['#2173B7', '#AA5CC2', '#7546C0', '#E4737D']}  />
    <div className={styles.container}>
      <Card
        padding="xl"
        radius="lg"
        withBorder
        style={{ maxWidth: 500 }}
      >
        <Stack align="center" gap="xl">
          <div className={styles.checkmark}>
            <IconCheck
              size={80}
              color="var(--mantine-color-teal-filled)"
              stroke={3}
            />
          </div>
          
          <Title order={1} className={styles.title}>
            Thank You for Subscribing!
          </Title>
          
          <Text size="lg" c="dimmed" className={styles.subtitle}>
            Welcome to unlimited access! Your support means the world to us.
            Get ready to explore and add as many games as you want to your collection.
          </Text>

          <Button
            component={Link}
            to="/profile"
            size="lg"
            variant="gradient"
            gradient={{ from: 'indigo', to: 'cyan' }}
            leftSection={<IconLibrary size={20} />}
            className={styles.button}
          >
            Go to Library
          </Button>
        </Stack>
      </Card>
    </div>
    </>
  )
}
