import { FC } from 'react'
import { Button, Card, Group, Progress, Text, useMantineTheme, useMantineColorScheme } from '@mantine/core'
import { IconCrown } from '@tabler/icons-react'
import Styles from './styles.module.scss'

export interface ISubscriptionBannerView {
  gameCount: number
  remainingGames: number
  progress: number
  onUpgradeClick: () => void
}

export const SubscriptionBannerView: FC<ISubscriptionBannerView> = ({
  gameCount,
  remainingGames,
  progress,
  onUpgradeClick,
}) => {
  const theme = useMantineTheme()
  const { colorScheme } = useMantineColorScheme()

  return (
    <Card 
      className={Styles.banner} 
      withBorder
      bg={colorScheme === 'dark' ? 'dark.6' : 'gray.0'}
    >
      <Group justify="space-between" align="flex-start">
        <div>
          <Text size="lg" fw={500} mb="xs">
            {remainingGames > 0
              ? `Only ${remainingGames} ${
                  remainingGames === 1 ? 'slot' : 'slots'
                } remaining!`
              : 'Game limit reached'}
          </Text>
          <Text size="sm" c="dimmed" mb="md">
            {remainingGames > 0
              ? 'Upgrade now to add unlimited games to your collection'
              : 'Subscribe to continue adding games to your collection'}
          </Text>
          <Progress
            value={progress}
            size="lg"
            radius="xl"
            mb="sm"
            color={
              progress >= 100
                ? theme.colors.red[colorScheme === 'dark' ? 8 : 6]
                : progress >= 80
                ? theme.colors.yellow[colorScheme === 'dark' ? 8 : 6]
                : theme.colors.blue[colorScheme === 'dark' ? 8 : 6]
            }
          />
          <Text size="xs" c="dimmed">
            {gameCount}/10 games used
          </Text>
        </div>
        <Button
          variant="gradient"
          gradient={{ from: 'indigo', to: 'cyan' }}
          leftSection={<IconCrown size={20} />}
          onClick={onUpgradeClick}
        >
          Upgrade Now
        </Button>
      </Group>
    </Card>
  )
}
