import { useState } from 'react'
import { Stack, Title, Text, Button, Card, Group, Image } from '@mantine/core'
import { useSpring, animated } from '@react-spring/web'
import { useAddSwipeResultMutation } from '../../graphql/graphql'
import type { IGetBoardGamesQuery } from '../../graphql/graphql'
import { cloudinaryBaseUrl } from '#lib/helpers.ts'

type BoardGame = NonNullable<NonNullable<NonNullable<IGetBoardGamesQuery['users']>[number]>['boardGames'][number]>['boardGame']

interface ISwipeInterfaceProps {
    sessionId: string
    participantId: string
    games: BoardGame[]
}

export function SwipeInterface({ sessionId, participantId, games }: ISwipeInterfaceProps) {
    const [currentIndex, setCurrentIndex] = useState(0)

    const addSwipeResultMutation = useAddSwipeResultMutation()

    const [props, api] = useSpring(() => ({
        x: 0,
        rotate: 0,
        scale: 1,
        config: { tension: 300, friction: 20 },
    }))

    const handleSwipe = async (liked: boolean) => {
        if (currentIndex >= games.length) return

        const game = games[currentIndex]

        // Animate card off screen
        api.start({
            x: liked ? 500 : -500,
            rotate: liked ? 45 : -45,
            scale: 0.8,
        })

        // Add swipe result
        addSwipeResultMutation.mutate({
            session_id: sessionId,
            participant_id: participantId,
            game_uuid: game.uuid,
            liked,
        })

        // Reset animation and move to next card
        api.start({
            x: 0,
            rotate: 0,
            scale: 1,
            immediate: true,
        })
        setCurrentIndex((prev) => prev + 1)
    }

    if (currentIndex >= games.length) {
        return (
            <Stack align="center" gap="xl" p="xl">
                <Title order={1}>All Done!</Title>
                <Text>You've rated all the games. Waiting for other participants...</Text>
            </Stack>
        )
    }

    const currentGame = games[currentIndex]

    return (
        <Stack align="center" gap="xl" p="xl" style={{ overflow: 'hidden' }}>
            <Title order={1}>Rate Games</Title>
            <Text>
                Game {currentIndex + 1} of {games.length}
            </Text>

            <animated.div style={{ ...props, touchAction: 'none' }}>
                <Card shadow="sm" padding="lg" radius="md" withBorder style={{ width: 360 }}>
                    <Card.Section style={{ height: 360, width: 360, overflow: "hidden" }}>
                        <Image
                            src={currentGame.cloudinaryPublicId ? `${cloudinaryBaseUrl(360, 360)}/${currentGame.cloudinaryPublicId}` : currentGame.thumbnailUrl}
                            alt={currentGame.title}
                            fit='cover'
                            width="360px"
                            height="360px"
                        />
                    </Card.Section>

                    <Stack mt="md" gap="sm">
                        <Title order={2} style={{ textAlign: 'center' }} lineClamp={1}>{currentGame.title}</Title>
                        <Text size="sm" c="dimmed" style={{ textAlign: 'center' }}>
                            {currentGame.minPlayers}-{currentGame.maxPlayers} Players • {currentGame.playTimeInMinutes} mins
                        </Text>
                    </Stack>
                </Card>
            </animated.div>

            <Group justify="center" gap="xl">
                <Button
                    color="red"
                    onClick={() => handleSwipe(false)}
                    loading={addSwipeResultMutation.isPending}
                >
                    Not Interested
                </Button>
                <Button
                    color="green"
                    onClick={() => handleSwipe(true)}
                    loading={addSwipeResultMutation.isPending}
                >
                    Interested
                </Button>
            </Group>
        </Stack>
    )
}
