import { Avatar, Group, UnstyledButton } from "@mantine/core"
import { IconDice6Filled } from "@tabler/icons-react"
import { useRouter } from "@tanstack/react-router"
import type { FC } from "react"
import { scrubbedDisplayName } from "#lib/helpers"

import type { IUserCard } from "./interfaces"
import Styles from "./styles.module.scss"

export const UserCard: FC<IUserCard> = ({
  avatarUrl,
  displayName,
  gamesCount,
  id,
  slug,
  backgroundImageUrl,
}) => {
  const router = useRouter()

  const handleClick = () => {
    router.history.push(`/users/${slug || id}`)
  }

  return (
    <UnstyledButton
      className={Styles.userCard}
      onClick={handleClick} style={{ "--bg-image": backgroundImageUrl ? `url(${backgroundImageUrl})` : "none" } as React.CSSProperties}
    >
      <Group wrap="nowrap">
        <Avatar className={Styles.avatar} radius="md" src={avatarUrl} />
        <div className={Styles.frostedGlass}>
          <div className={Styles.name}>{scrubbedDisplayName(displayName)}</div>
          <Group gap={10} mt={3} wrap="nowrap">
            <IconDice6Filled className={Styles.icon} stroke={1.5} />
            <div>{gamesCount}</div>
          </Group>
        </div>
      </Group>
    </UnstyledButton>
  )
}
