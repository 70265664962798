import { FC } from 'react'
import { useUserData } from '@nhost/react'
import { useGetGameCountQuery } from '#graphql/graphql'
import { SubscriptionBannerView } from './view'

export const SubscriptionBanner: FC = () => {
  const user = useUserData()
  const { data, isLoading } = useGetGameCountQuery(
    { userId: user?.id ?? '' },
    { enabled: !!user?.id }
  )

  const handleOnUpgrade = async () => {
    const url = `https://klptlepcyyukmlonwiyb.functions.eu-central-1.nhost.run/v1/stripe/createPaymentLink?user_id=${user?.id}`
    const response = await fetch(url)
    const { paymentLink } = await response.json()
    window.location.replace(paymentLink)
  }

  if (isLoading || !data) return null

  const gameCount = data.user?.boardGames_aggregate.aggregate?.count ?? 0
  const subscription = data.user_subscriptions[0]
  const hasActiveSubscription =
    subscription?.subscription_status === 'active' &&
    (!subscription.trial_end || new Date(subscription.trial_end) > new Date())

  // Don't show banner if user has active subscription or less than 8 games
  if (hasActiveSubscription || gameCount < 8) return null

  const remainingGames = Math.max(0, 10 - gameCount)
  const progress = (gameCount / 10) * 100

  return (
    <SubscriptionBannerView
      gameCount={gameCount}
      remainingGames={remainingGames}
      progress={progress}
      onUpgradeClick={handleOnUpgrade}
    />
  )
}
