import { useUserData } from "@nhost/react"
import { createFileRoute } from "@tanstack/react-router"
import { LibraryWithScrollTracking } from "#components/Library"
import { StatsBarContainer } from "#containers/StatsBar"
import { ScreenSizes, useScreenSize } from "#contexts/ScreenSizeContext"
import { useGetBoardGamesQuery } from "#graphql/graphql"
import { TopNavWithSidebarAndMain } from "#layouts/TopNavWithSidebarAndMain"

const Profile = () => {
  const user = useUserData()
  const { screenSize } = useScreenSize()
  const screenSizeTooSmall = [
    ScreenSizes.MD,
    ScreenSizes.SM,
    ScreenSizes.XS,
  ].includes(screenSize)
  const { data, error, isLoading, refetch } = useGetBoardGamesQuery({
    uuid: user?.id,
  })

  if (error) {
    console.error("Could not authenticated because: ", error)
  }

  if (isLoading) {
    return null
  }

  return (
    <TopNavWithSidebarAndMain
      mainContent={<LibraryWithScrollTracking data={data?.users[0]} refetch={refetch} />}
      sidebarContent={screenSizeTooSmall ? null : <StatsBarContainer />}
    />
  )
}
export const Route = createFileRoute("/_authenticated/profile")({
  component: Profile,
})
