import { Group, Paper, Stack, Text, TextInput, Title } from "@mantine/core"
import { IconSearch, IconUsers } from "@tabler/icons-react"
import { useState } from "react"
import { UserCard } from "../UserCard"
import Styles from "./styles.module.scss"
import { IGetUsersWithBoardGamesQuery } from "#graphql/graphql.ts"
import { cloudinaryBaseUrl } from "#lib/helpers.ts"


export const UsersWithBoardGames = ({ users }: IGetUsersWithBoardGamesQuery) => {
  const [search, setSearch] = useState("")

  const filteredUsers = users.filter((user: IGetUsersWithBoardGamesQuery["users"][0]) =>
    user.displayName.toLowerCase().includes(search.toLowerCase()),
  )

  return (
    <div className={Styles.container}>
      <Paper className={Styles.header} shadow="md">
        <Stack gap="lg">
          <Group gap="md" align="flex-start">
            <IconUsers size={32} className={Styles.headerIcon} />
            <div>
              <Title order={2} className={Styles.title}>Board Game Enthusiasts</Title>
              <Text className={Styles.subtitle}>
                Discover fellow board game collectors and their amazing libraries
              </Text>
            </div>
          </Group>
          <TextInput
            placeholder="Search users..."
            leftSection={<IconSearch size={18} style={{ color: 'var(--mantine-color-blue-5)' }} />}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            className={Styles.search}
            variant="filled"
            radius="md"
            size="md"
          />
        </Stack>
      </Paper>
      <div className={Styles.userCards}>
        {filteredUsers.map((user) => (
          <div className={Styles.cardWrapper} key={user.id}>
            <UserCard
              avatarUrl={user.avatarUrl}
              displayName={user.displayName}
              gamesCount={user?.boardGames_aggregate?.aggregate?.count ?? 0}
              id={user.id}
              slug={user.slug}
              backgroundImageUrl={user.boardGames?.[0]?.boardGame?.cloudinaryPublicId
                ? `${cloudinaryBaseUrl(400, 400)}/${user.boardGames[0].boardGame.cloudinaryPublicId}`
                : (user.boardGames?.[0]?.boardGame?.imageUrl ?? undefined)}
            />
          </div>
        ))}

        {filteredUsers.length === 0 && (
          <Text ta="center" c="dimmed" py="xl" size="lg" className={Styles.noResults}>
            No users found matching your search
          </Text>
        )}
      </div>
    </div>
  )
}
