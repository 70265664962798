import { useParams } from "@tanstack/react-router"
import { LibraryWithScrollTracking } from "#components/Library"
import { UserCard } from "#components/UserCard"
import { ScreenSizes, useScreenSize } from "#contexts/ScreenSizeContext"
import { useGetBoardGamesQuery } from "#graphql/graphql"
import { TopNavWithSidebarAndMain } from "#layouts/TopNavWithSidebarAndMain"
import Styles from "#screens/UserScreen/styles.module.scss"
import { StatsBarContainer } from "../../containers/StatsBar"
import { identifyInputType } from "#lib/helpers.ts"
import { NotFound } from "#components/NotFound/index.tsx"

export const UserScreen = () => {
  const { screenSize } = useScreenSize()
  const screenSizeTooSmall = [ScreenSizes.SM, ScreenSizes.XS].includes(
    screenSize,
  )
  const { userId } = useParams({ from: "/_public/users/$userId" })
  // use a regex on userId to determine if its a uuid or slug
  const critiria = identifyInputType(userId) === "uuid" ? { uuid: userId } : { slug: userId }

  const { data, error, isLoading, refetch } = useGetBoardGamesQuery(critiria)

  if (isLoading) {
    return null
  }

  if (!data?.users?.length) {
    return <NotFound />
  }

  const user = data.users[0]

  if (error) {
    console.error("Could not fetch board games because: ", error)
  }

  const sideBarContent = () => {
    if (screenSizeTooSmall) {
      return null
    }

    return (
      <div className={Styles.sideBarContent}>
        <UserCard
          avatarUrl={user.avatarUrl}
          displayName={user.displayName}
          gamesCount={user.boardGames.length}
          id={user.id}
        />
        <StatsBarContainer user={user} />
      </div>
    )
  }

  return (
    <TopNavWithSidebarAndMain
      mainContent={<LibraryWithScrollTracking data={user} refetch={refetch} />}
      sidebarContent={sideBarContent()}
    />
  )
}
