import {
  Button,
  CloseButton,
  Modal,
  TextInput,
  UnstyledButton,
  Tooltip,
} from "@mantine/core"
import { useClickOutside, useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { useUserData } from "@nhost/react"
import { IconSearch, IconX, IconCards } from "@tabler/icons-react"
import { AnimatePresence, motion } from "framer-motion"
import { type FC, useRef, useState } from "react"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { BoardGamePreview } from "#components/BoardGamePreview"
import { EmptyLibrary } from "#components/Library/EmptyLibrary"
import { Search } from "#components/Search"
import { Shelf } from "#components/shelf"
import { BoardGameDetails } from "#containers/BoardGameDetails"
import { ScreenSizes, useScreenSize } from "#contexts/ScreenSizeContext"
import {
  type IGetBoardGamesQuery,
  type Maybe,
  useAddBoardGameMutation,
  useUpdateRatingMutation,
} from "#graphql/graphql"
import { SubscriptionBanner } from "#components/SubscriptionBanner"
import { useGetGameCountQuery } from "#graphql/graphql"
import type { ILibrary } from "./interfaces"
import Styles from "./styles.module.scss"


const Library: FC<ILibrary> = ({ data, refetch, scrollPosition }) => {
  const { isMobile, screenSize } = useScreenSize()
  const user = useUserData()
  const { data: gameCountData } = useGetGameCountQuery(
    { userId: user?.id ?? '' },
    { enabled: !!user?.id }
  )

  const gameCount = gameCountData?.user?.boardGames_aggregate.aggregate?.count ?? 0
  const subscription = gameCountData?.user_subscriptions[0]
  const hasActiveSubscription =
    subscription?.subscription_status === 'active' &&
    (!subscription.trial_end || new Date(subscription.trial_end) > new Date())
  const canAddGames = gameCount < 10 || hasActiveSubscription
  const searchTextReference = useRef<HTMLInputElement>(null)
  const [searchText, setSearchText] = useState("")
  const [opened, { close, open }] = useDisclosure(false)
  const [boardGameDetails, setBoardGameDetails] = useState<{
    boardGame:
    NonNullable<IGetBoardGamesQuery["users"]>[0]["boardGames"][0]["boardGame"]
    , rating: number
  } | null>(null)
  const { mutate: updateRating } = useUpdateRatingMutation()
  const clickOutside = useClickOutside(() => setBoardGameDetails(null))
  const closeModal = () => {
    setBoardGameDetails(null)
  }
  const { isPending, mutate } = useAddBoardGameMutation()

  const AddGameModal = () => {
    return (
      <Modal
        centered={true}
        fullScreen={isMobile}
        onClose={close}
        opened={opened}
        size={"auto"}
        title={"Add board game"}
      >
        <motion.div>
          <Search mutationLoading={isPending} onAdd={handleAdd} />
        </motion.div>
      </Modal>
    )
  }

  const handleAdd = async (id: Maybe<number> | undefined) => {
    if (!id) {
      return null
    }

    mutate(
      { bggId: id },
      {
        onSuccess: (mutationData) => {
          if (refetch) {
            refetch()
          }

          close()
          if (!mutationData) {
            return
          }

          const element = document.querySelector(
            `[data-id="${mutationData?.addGame?.boardGameUuid}"]`,
          )
          if (!element) {
            return
          }

          notifications.show({
            message: "The game has been added to your library ",
            title: "Game Added",
          })

          return element.scrollIntoView({ behavior: "smooth" })
        },
        onError: (error: any) => {
          const errorData = error?.response?.data

          if (errorData?.subscriptionRequired) {
            notifications.show({
              color: "red",
              message: "Please upgrade to add more games to your collection",
              title: "Game Limit Reached",
            })
          } else {
            notifications.show({
              color: "red",
              message: "Failed to add game to your library",
              title: "Error",
            })
          }
        },
      },
    )
  }

  const handleOnClick = (item: NonNullable<IGetBoardGamesQuery["users"][0]>["boardGames"][0]) => {
    setBoardGameDetails({ boardGame: item.boardGame, rating: item.rating })
  }

  const itemHeights: Record<ScreenSizes, number> = {
    [ScreenSizes.LG]: 140,
    [ScreenSizes.MD]: 150,
    [ScreenSizes.SM]: 125,
    [ScreenSizes.XL]: 150,
    [ScreenSizes.XS]: 75,
  }

  const filteredGames = data?.boardGames?.filter(({ boardGame }) =>
    boardGame.title.toLowerCase().includes(searchText.toLowerCase().trim()),
  )

  const hasGames = filteredGames && filteredGames.length > 0
  const isOwner = user && user.id === data?.id
  if (!(hasGames || searchText)) {
    return (
      <>
        <EmptyLibrary onAddGame={open} isOwner={!!isOwner} />
        <AddGameModal />
      </>
    )
  }

  const handleRatingChange = (boardGameUuid: string, rating: number) => {
    updateRating(
      { boardGameUuid, rating },
      {
        onSuccess: () => {
          if (refetch) {
            refetch()
          }
        },
        onError: () => {
          notifications.show({
            color: "red",
            message: "Failed to update rating",
            title: "Error",
          })
        },
      }
    )
  }

  const items = filteredGames?.map((item) => {
    return (
      <BoardGamePreview
        data={item.boardGame}
        itemHeight={itemHeights[screenSize]}
        key={item.boardGame.uuid}
        onClick={() => handleOnClick(item)}
        scrollPosition={scrollPosition}
        selected={boardGameDetails?.boardGame?.uuid === item.boardGame.uuid}
        rating={item.rating ?? 0}
        onRatingChange={isOwner ? handleRatingChange : undefined}
      />
    )
  })

  return (
    <div className={Styles.libraryContainer}>
      {isOwner && <SubscriptionBanner />}
      <div className={Styles.actions}>
        <TextInput
          className={Styles.search}
          leftSection={
            <IconSearch stroke={1.5} style={{ height: 12, width: 12 }} />
          }
          mb="sm"
          onChange={(event) => setSearchText(event.target.value)}
          placeholder="Search"
          ref={searchTextReference}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => {
                setSearchText("")
                if (searchTextReference.current) {
                  searchTextReference.current.value = ""
                }
              }}
              style={{ display: searchText ? undefined : "none" }}
            />
          }
          size="xs"
        />
        {/* Swipe Games button - visible to everyone */}
        <Button
          className={Styles.addButton}
          onClick={() => {
            window.location.href = `/users/${data?.slug}/swipe/`
          }}
          size={"xs"}
          leftSection={<IconCards size={16} />}
        >
          {screenSize === ScreenSizes.XS ? "Swipe" : "Swipe Games"}
        </Button>
        
        {isOwner && (
          <Tooltip
            label="Upgrade to add more games"
            disabled={canAddGames}
          >
            <Button
              className={Styles.addButton}
              onClick={open}
              size={"xs"}
              disabled={!canAddGames}
            >
              {screenSize === ScreenSizes.XS ? "Add" : "Add board game"}
            </Button>
          </Tooltip>
        )}
      </div>
      <Shelf itemHeight={itemHeights[screenSize]} items={items} />
      <AnimatePresence>
        {boardGameDetails && (
          <div className={Styles.modal} id={"BoardGamesDetailModal"}>
            <motion.div
              animate={{ opacity: 1 }}
              className={Styles.modalOverlay}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            />
            <motion.div
              className={Styles.modalContent}
              layoutId={boardGameDetails.boardGame.uuid}
              onClick={(clickEvent) => clickEvent.preventDefault()}
              ref={clickOutside}
              transition={{
                damping: 30,
                duration: 0.2,
                stiffness: 500,
                type: "spring",
              }}
            >
              <UnstyledButton
                className={Styles.closeModal}
                onClick={closeModal}
              >
                <IconX />
              </UnstyledButton>
              <BoardGameDetails
                thumbnailUrl={boardGameDetails.boardGame.thumbnailUrl || '/images/not-found.png'}
                cloudinaryPublicId={boardGameDetails.boardGame.cloudinaryPublicId ?? undefined}
                uuid={boardGameDetails.boardGame.uuid}
                onRatingChange={isOwner ? handleRatingChange : undefined}
                rating={boardGameDetails.rating ?? 0}

              />
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      <AddGameModal />
    </div>
  )
}

export const LibraryWithScrollTracking = trackWindowScroll(Library)
