const EMAIL_TEST_REGEX = /[.@]/

export const scrubbedDisplayName = (displayName: string): string => {
  if (new RegExp(EMAIL_TEST_REGEX).test(displayName)) {
    return displayName.split("@")[0]
  }

  return displayName
}

export const identifyInputType = (input: string): "uuid" | "slug" | "invalid" => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const slugRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/; // Allows lowercase letters, numbers, and hyphens (no special chars)

  if (uuidRegex.test(input)) {
    return "uuid";
  } else if (slugRegex.test(input)) {
    return "slug";
  } else {
    return "invalid";
  }
}

export const cloudinaryBaseUrl = (h: number = 400, w: number = 400) => `https://res.cloudinary.com/govannon/c_limit,f_auto,h_${h},q_auto,w_${w}/v1734947498`
