import type { FC } from "react"
import { ProductBox } from "#components/ProductBox"
import type { IGetBoardGameQuery, Maybe } from "#graphql/graphql"
import "react-lazy-load-image-component/src/effects/blur.css"
import cx from "clsx"
import { IconStar } from "@tabler/icons-react"
import Markdown from "react-markdown"
import { Badge } from "../Badge"
import { BoardGamePlayerInfo } from "../BoardGamePlayerInfo"
import { CleanText } from "../CleanHTML"
import { ReadMore } from "../ReadMore"
import Styles from "./styles.module.scss"
import { cloudinaryBaseUrl } from "#lib/helpers.ts"

const renderBadges = (
  boardGame: NonNullable<IGetBoardGameQuery["boardGame"]>,
) => {
  return (
    <div className={Styles.badges}>
      {boardGame.categories.slice(0, 5).map((category) => (
        <Badge key={category.category?.value} text={category.category?.value} />
      ))}
      {boardGame.mechanics.slice(0, 5).map((mechanic) => (
        <Badge key={mechanic.mechanic?.value} text={mechanic.mechanic?.value} />
      ))}
    </div>
  )
}

const imageUrl = (
  cloudinaryPublicId: Maybe<string> | undefined,
  imageUrl: Maybe<string> | undefined,
  thumbnailUrl: string,
) => {
  if (cloudinaryPublicId) {
    return `${cloudinaryBaseUrl}/${cloudinaryPublicId}`
  }
  if (imageUrl) {
    return imageUrl
  }
  return thumbnailUrl
}

interface BoardGameDetailsProps extends NonNullable<IGetBoardGameQuery["boardGame"]> {
  rating?: number
  onRatingChange?: (uuid: string, rating: number) => void
}

export const BoardGameDetails: FC<BoardGameDetailsProps> = ({
  rating = 0,
  onRatingChange,
  ...boardGame
}) => {
  const handleStarClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (onRatingChange) {
      onRatingChange(boardGame.uuid, rating === 5 ? 0 : 5)
    }
  }
  return (
    <div className={Styles.details}>
      <h1>{boardGame.title}</h1>
      <div className={Styles.topPart}>
        <ProductBox
          imageUrl={imageUrl(
            boardGame.cloudinaryPublicId,
            boardGame.imageUrl,
            boardGame.thumbnailUrl,
          )}
          thumbUrl={boardGame.thumbnailUrl}
        />

        {onRatingChange && (
          <IconStar
            className={cx(Styles.starIcon, rating === 5 && Styles.active)}
            onClick={handleStarClick}
            size={24}
            fill={rating === 5 ? "currentColor" : "none"}
          />
        )}
        <div className={Styles.categoryBadges}>{renderBadges(boardGame)}</div>
      </div>

      <BoardGamePlayerInfo
        minPlayers={boardGame.minPlayers}
        maxPlayers={boardGame.maxPlayers}
        recommendedPlayerCount={boardGame.recommendedPlayerCount}
        nonRecommendedPlayerCount={boardGame.nonRecommendedPlayerCount}
        playTimeInMinutes={boardGame.playTimeInMinutes}
        minAge={boardGame.minAge}
      />
      <div className={Styles.description}>
        {boardGame.summary ? (
          <Markdown
            components={{
              h1: "p",
              h2: "p",
              em(props) {
                const { children } = props
                return <Badge text={children as string} />
              },
            }}
          >
            {boardGame.summary}
          </Markdown>
        ) : (
          <ReadMore
            id="description"
            text={CleanText(boardGame.summary ?? boardGame.description)}
          />
        )}
      </div>
    </div>
  )
}
