/**
 * Interface for board game objects with player count recommendations
 */
export interface BoardGame {
  recommendedPlayerCount?: number | null;
  nonRecommendedPlayerCount?: number | null;
  [key: string]: unknown;
}

/**
 * Filters games based on player count recommendations
 * 
 * @param games Array of board game objects
 * @param playerCount The number of players in the session
 * @param excludeNonRecommended Whether to exclude games not recommended for this player count (default: false)
 * @returns Filtered array of games
 */
export function filterGamesByPlayerCount<T extends BoardGame>(
  games: T[], 
  playerCount: number,
  excludeNonRecommended = false
): T[] {
  return games.filter(game => {
    // Check if this player count matches the recommended player count
    const isRecommended = game.recommendedPlayerCount === playerCount;
    
    // Check if this player count matches the non-recommended player count
    const isNonRecommended = game.nonRecommendedPlayerCount === playerCount;
    
    // Include the game if:
    // 1. It's in the recommended list, OR
    // 2. We're not excluding non-recommended games OR this count isn't in the non-recommended list
    return isRecommended || (!excludeNonRecommended || !isNonRecommended);
  });
}
