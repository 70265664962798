import { createFileRoute } from '@tanstack/react-router'
import { SwipeHostScreen } from '#screens/SwipeHostScreen'

export const Route = createFileRoute('/users/$userId/swipe/')({
  component: SwipeHostRoute,
})

function SwipeHostRoute() {
  const { userId } = Route.useParams()
  return <SwipeHostScreen userId={userId} />
}
