import type { FC } from "react"
import { useGetBoardGameQuery } from "#/graphql/graphql"
import "react-lazy-load-image-component/src/effects/blur.css"
import { useDebounceCallback } from "usehooks-ts"
import { BoardGameDetails as BoardGameDetailsComponent } from "#components/BoardGameDetails"
import { Skeleton } from "#components/BoardGameDetails/Skeleton"
import { cloudinaryBaseUrl } from "#lib/helpers.ts"

interface IBoardGameDetails {
  cloudinaryPublicId?: string
  thumbnailUrl?: string
  uuid: string
  onRatingChange?: (uuid: string, rating: number) => void
  rating?: number
}

export const BoardGameDetails: FC<IBoardGameDetails> = ({
  cloudinaryPublicId,
  thumbnailUrl,
  uuid,
  onRatingChange,
  rating
}) => {
  const { data, error, isLoading, refetch } = useGetBoardGameQuery({
    uuid,
  })
  const debouncedRefetch = useDebounceCallback(refetch, 1000)

  if (error) {
    debouncedRefetch()
  }

  return isLoading || !data?.boardGame ? (
    <Skeleton
      thumbnailUrl={
        cloudinaryPublicId
          ? `${cloudinaryBaseUrl}/${cloudinaryPublicId}`
          : thumbnailUrl ?? ''
      }
    />
  ) : (
    <BoardGameDetailsComponent {...data.boardGame} onRatingChange={onRatingChange} rating={rating} />
  )
}
