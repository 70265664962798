import { useEffect, useState } from 'react'
import { Button, Stack, Text, Title, TextInput, Loader, Switch } from '@mantine/core'
import { useAddSwipeSessionParticipantMutation, useGetSwipeSessionQuery, useGetBoardGamesWithFilterQuery, useGetSwipeResultsQuery } from '../../graphql/graphql'
import type { IGetSwipeSessionQuery, IGetBoardGamesWithFilterQuery } from '../../graphql/graphql'
import { SwipeInterface } from '../../components/SwipeInterface'
import { SwipeResults } from '../../components/SwipeResults'
import { ProgressView } from '../../components/ProgressView'
import { filterGamesByPlayerCount } from '../../lib/gameFilters'
import { useUserData } from '@nhost/react'
type Participant = NonNullable<NonNullable<IGetSwipeSessionQuery['swipe_sessions_by_pk']>['session_participants'][number]>

type BoardGameEntry = NonNullable<NonNullable<NonNullable<IGetBoardGamesWithFilterQuery['users']>[number]>['boardGames'][number]>

interface SwipeParticipantScreenProps {
    sessionId: string
}

export function SwipeParticipantScreen({ sessionId }: SwipeParticipantScreenProps) {
    const [sessionInitalizing, setSessionInitalizing] = useState(false)
    const [participantId, setParticipantId] = useState<string | null>(null)
    const [autoJoin, setAutoJoin] = useState(false)
    const [allFinished, setAllFinished] = useState(false)
    const user = useUserData()
    const [nickname, setNickname] = useState(user?.displayName ?? '')

    const { data: sessionData,  } = useGetSwipeSessionQuery(
        { session_id: sessionId },
        {
            queryKey: ['getSwipeSession', sessionId],
            enabled: !!sessionId,
            refetchInterval: allFinished ? false : 1000, // Stop polling when all finished

        }
    )

    const { data: gamesData } = useGetBoardGamesWithFilterQuery(
        {
            uuid: sessionData?.swipe_sessions_by_pk?.library_uuid || '',
            players: sessionData?.swipe_sessions_by_pk?.player_count || 1,
            playTimeInMinutesMin: sessionData?.swipe_sessions_by_pk?.min_play_time || 0,
            playTimeInMinutesMax: sessionData?.swipe_sessions_by_pk?.max_play_time || 9999,
        },
        {
            enabled: !!sessionData?.swipe_sessions_by_pk?.library_uuid,
        }
    )

    const { data: resultsData } = useGetSwipeResultsQuery(
        { session_id: sessionId },
        {
            enabled: !!sessionId,
            refetchInterval: allFinished ? false : 1000, // Stop polling when all finished
        }
    )

    const addParticipantMutation = useAddSwipeSessionParticipantMutation({
        onSuccess: (data) => {
            if (data.insert_swipe_session_participants_one?.participant_id) {
                setParticipantId(data.insert_swipe_session_participants_one.participant_id)
            }
        },
    })

    const handleJoinSession = () => {
        console.log("joining session before", sessionId, nickname)
        if (!sessionId || !nickname.trim()) return
        console.log("joining session man", sessionId, nickname)
        addParticipantMutation.mutate({
            session_id: sessionId,
            user_identifier: nickname,
        })
    }

    useEffect(() => {        
        if (!sessionId || !user) return
        if (sessionInitalizing) return

        console.log("joining session with", sessionId, user?.displayName)
        setSessionInitalizing(true)
        setNickname(user?.displayName ?? '')
        setAutoJoin(true)
    }, [sessionId, user, sessionInitalizing] )

    useEffect(() => {
        if (!autoJoin) return
        
        handleJoinSession()
    }, [autoJoin ] )

    if (!sessionData?.swipe_sessions_by_pk) {
        return (
            <Stack align="center" gap="xl" p="xl">
                <Title order={1}>Session Not Found</Title>
                <Text>This swipe session does not exist or has expired.</Text>
            </Stack>
        )
    }

    

    if (!participantId) {
        return (
            <Stack align="center" gap="xl" p="xl">
                <Title order={1}>Join Swipe Session</Title>
                <TextInput
                    label="Your Nickname"
                    placeholder="Enter a nickname"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                />
                <Button
                    onClick={handleJoinSession}
                    loading={addParticipantMutation.isPending}
                    disabled={!nickname.trim()}
                >
                    Join Session
                </Button>
            </Stack>
        )
    }

    if (sessionData.swipe_sessions_by_pk.status === 'in_progress') {
        const playerCount = sessionData.swipe_sessions_by_pk.player_count || 1
        const allGames = gamesData?.users[0]?.boardGames.map((bg: BoardGameEntry) => bg.boardGame) || []
        const filteredGames = filterGamesByPlayerCount(allGames, playerCount, true)
        if (allGames.length === 0) {
            return (
                <Stack align="center" gap="xl" p="xl">
                    <Title order={1}>Loading Games...</Title>
                    <Text>Please wait while we load the games.</Text>
                </Stack>
            )
        }

        // Check if this participant has rated all games
        const participantResults = resultsData?.swipe_results.filter(
            (result) => result.participant_id === participantId
        ) || []

        if (participantResults.length === filteredGames.length) {
            if (!resultsData) {
                return (
                    <Stack align="center" gap="xl" p="xl">
                        <Title order={1}>Loading Results...</Title>
                        <Loader size="xl" />
                    </Stack>
                )
            }

            // Check if all participants have finished
            const participants = sessionData.swipe_sessions_by_pk.session_participants
            const allParticipantsFinished = participants.every(participant => {
                const results = resultsData.swipe_results.filter(
                    result => result.participant_id === participant.participant_id
                )
                return results.length === filteredGames.length
            })

            if (allParticipantsFinished) {
                // Set allFinished to true to stop polling
                if (!allFinished) {
                    setAllFinished(true)
                }
                return <SwipeResults sessionId={sessionId} />
            }

            return (
                <ProgressView
                    sessionData={sessionData.swipe_sessions_by_pk}
                    resultsData={resultsData}
                    gamesData={gamesData}
                    currentParticipantId={participantId}
                />
            )
        }

        return (
            <Stack>
                <SwipeInterface
                    sessionId={sessionId}
                    participantId={participantId}
                    games={filteredGames}
                />
            </Stack>
        )
    }

    return (
        <Stack align="center" gap="xl" p="xl">
            <Title order={1}>Waiting Room</Title>
            <Text>Waiting for the host to start the session...</Text>

            <Stack gap="md">
                <Title order={2}>Participants</Title>
                {sessionData.swipe_sessions_by_pk.session_participants.map((participant: Participant) => (
                    <Text key={participant.participant_id}>
                        {participant.user_identifier || 'Anonymous'}
                        {participant.is_ready ? ' (Ready)' : ''}
                    </Text>
                ))}
            </Stack>
        </Stack>
    )
}
