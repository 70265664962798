import { useState } from 'react'
import { Button, Stack, Text, Title, Group, Badge } from '@mantine/core'
import { QRCodeSVG } from 'qrcode.react'

interface SwipeWaitingRoomProps {
  sessionUrl: string
  participants: Array<{
    participant_id: string
    user_identifier: string | null
    is_ready: boolean
  }>
  onStartSession: (settings: {
    playerCount: number
    minPlayTime: number | null
    maxPlayTime: number | null
  }) => void
  isStarting: boolean
}

export function SwipeWaitingRoom({
  sessionUrl,
  participants,
  onStartSession,
  isStarting
}: SwipeWaitingRoomProps) {
  const [quickSelected, setQuickSelected] = useState<boolean>(false)
  const [mediumSelected, setMediumSelected] = useState<boolean>(false)
  const [longSelected, setLongSelected] = useState<boolean>(false)

  // Handle button clicks with matrix-style selection
  const handleQuickClick = () => {
    if (quickSelected) {
      // If already selected, just toggle it off
      setQuickSelected(false)
    } else {
      // If not selected, toggle it on and handle combinations
      setQuickSelected(true)
      
      // If both medium and long are selected, turn off long
      if (mediumSelected && longSelected) {
        setLongSelected(false)
      }
    }
  }

  const handleMediumClick = () => {
    if (mediumSelected) {
      // If already selected, just toggle it off
      setMediumSelected(false)
    } else {
      // If not selected, toggle it on and handle combinations
      setMediumSelected(true)
      
      // If both quick and long are selected, turn off long
      if (quickSelected && longSelected) {
        setLongSelected(false)
      }
    }
  }

  const handleLongClick = () => {
    if (longSelected) {
      // If already selected, just toggle it off
      setLongSelected(false)
    } else {
      // If not selected, toggle it on and handle combinations
      setLongSelected(true)
      
      // If both quick and medium are selected, turn off medium
      if (quickSelected && mediumSelected) {
        setMediumSelected(false)
      }
    }
  }

  const handleStartSession = () => {
    // Calculate min and max play times based on selections
    let minPlayTime: number | null = null
    let maxPlayTime: number | null = null
    
    if (quickSelected) minPlayTime = 0
    else if (mediumSelected) minPlayTime = 30
    
    if (longSelected) maxPlayTime = 999 // Effectively no upper limit
    else if (mediumSelected) maxPlayTime = 120
    else if (quickSelected) maxPlayTime = 30
    
    // Use participants.length as the player count
    const playerCount = Math.max(participants.length, 1)
    
    onStartSession({
      playerCount,
      minPlayTime,
      maxPlayTime
    })
  }

  return (
    <Stack align="center" gap="xl" p="xl">
      <Title order={1}>Swipe Session</Title>

      <Stack align="center" gap="md">
        <Text>Scan to join:</Text>
        <QRCodeSVG value={sessionUrl} size={200} />
        <Text>Or share this link:</Text>
        <Text>{sessionUrl}</Text>
      </Stack>

      <Stack gap="md">
        <Title order={2}>Participants</Title>
        {participants.map((participant) => (
          <Text key={participant.participant_id}>
            {participant.user_identifier ?? 'Anonymous'}
            {participant.is_ready ? ' (Ready)' : ''}
          </Text>
        ))}
      </Stack>
      
      <Stack gap="md" w="100%" maw={400}>
        <Title order={3}>Game Settings</Title>
        
        <Stack gap="xs">
          <Group justify="space-between">
            <Text fw={500}>Game Duration</Text>
            <Badge size="lg" color="blue">Players: {participants.length}</Badge>
          </Group>
          <Text size="sm" color="dimmed">
            Select up to two duration options (quick-medium, quick-long, or medium-long)
          </Text>
          <Group>
            <Button 
              variant={quickSelected ? "filled" : "outline"} 
              onClick={handleQuickClick}
            >
              Quick (30 minutes)
            </Button>
            <Button 
              variant={mediumSelected ? "filled" : "outline"} 
              onClick={handleMediumClick}
            >
              Medium (2 hours)
            </Button>
            <Button 
              variant={longSelected ? "filled" : "outline"} 
              onClick={handleLongClick}
            >
              Long (2+ hours)
            </Button>
          </Group>
        </Stack>
      </Stack>

      <Button
        onClick={handleStartSession}
        disabled={participants.length <= 1 || (!quickSelected && !mediumSelected && !longSelected)}
        loading={isStarting}
      >
        Start Session
      </Button>
    </Stack>
  )
}
