import { Progress, Stack, Text, Title } from '@mantine/core'
import type { IGetSwipeSessionQuery, IGetSwipeResultsQuery, IGetBoardGamesQuery, IGetBoardGamesWithFilterQuery } from '../graphql/graphql'
import { BoardGame, filterGamesByPlayerCount } from '#lib/gameFilters'
type Participant = NonNullable<NonNullable<IGetSwipeSessionQuery['swipe_sessions_by_pk']>['session_participants'][number]>

interface ProgressViewProps {
    sessionData: NonNullable<IGetSwipeSessionQuery['swipe_sessions_by_pk']>
    resultsData: NonNullable<IGetSwipeResultsQuery>
    gamesData: BoardGame[]
    currentParticipantId: string
}

export function ProgressView({ sessionData, resultsData, gamesData, currentParticipantId }: ProgressViewProps) {
    const games = gamesData || []

    if (games.length === 0) {
        return (
            <Stack align="center" gap="xl" p="xl">
                <Title order={1}>Loading Games...</Title>
                <Text>Please wait while we load the games.</Text>
            </Stack>
        )
    }

    return (
        <Stack align="center" gap="xl" p="xl">
            <Title order={1}>Waiting for others to finish...</Title>

            <Stack gap="md" style={{ width: '100%', maxWidth: '500px' }}>
                <Title order={2}>Progress</Title>
                {sessionData.session_participants.map((participant: Participant) => {
                    const participantResults = resultsData.swipe_results.filter(
                        (result) => result.participant_id === participant.participant_id
                    )
                    const progress = (participantResults.length / games.length) * 100

                    return (
                        <Stack key={participant.participant_id} gap="xs">
                            <Text>
                                {participant.user_identifier || 'Anonymous'}
                                {participant.participant_id === currentParticipantId ? ' (You)' : ''}
                            </Text>
                            <Progress
                                value={progress}
                                color={progress === 100 ? 'green' : 'blue'}
                                size="lg"
                                radius="xl"
                                striped
                                animated={progress < 100}
                            />
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}
