import { createFileRoute } from '@tanstack/react-router'
import { SwipeParticipantScreen } from '#screens/SwipeParticipantScreen'

export const Route = createFileRoute('/swipe_session/$sessionId')({
    component: SwipeParticipantRoute,
})

function SwipeParticipantRoute() {
    const { sessionId } = Route.useParams()
    return <SwipeParticipantScreen sessionId={sessionId} />
}
